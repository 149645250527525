import { Helpers, Polyfills } from 'pistachio'
import Glass from 'glass'

import '@hotwired/turbo-rails'
import * as Sentry from "@sentry/browser"

if (process.env.ENABLE_FRONTEND_ERROR_TRACKING == 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.SENTRY_RELEASE,

    maxBreadcrumbs: 50,

    integrations: [
      Sentry.browserTracingIntegration(),
    ],

    tracesSampleRate: 0.05,
  });

  window.Sentry = Sentry
}

Polyfills.checkAndRun(main)

function main(err) {
  require('@rails/activestorage').start()

  require('pistachio/app/components')

  require('../component_styles')
  require('../stimulus_controllers')

  document.addEventListener('turbo:load', (event) => {
    Helpers.clarifyEmailAddresses('koleo.ch')

    if (event.detail && event.detail.timing && Object.keys(event.detail.timing).length > 0) {
      Glass.trackXHR(event.detail.timing)
    } else {
      Glass.trackPageLoad()
    }

    if (!Polyfills.browserSupportsAllFeatures()) {
      Glass.track('browser::polyfills_required', Polyfills.featureSupportList())
    }
  })
}


import 'pistachio/app/base'
import '../global.css'

import * as images from '../images/**/*'
images.default.map((image) => image)
