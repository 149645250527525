import { Application } from '@hotwired/stimulus'

const application = Application.start()
application.handleError = (error, message, detail) => {
  console.warn(error, message, detail)
  if (window.Sentry) {
    window.Sentry.captureException(error)
  }
}

export default application
